import React from 'react'
import Person from './person'
import { TeamContainer, TeamInner } from './styles'

const Team = () => (
  <div id="team">
    <TeamContainer>
      <h1 style={{
        padding: '50px 0px 0px 0px',
        color: 'rgb(0, 0, 0)',
        fontSize: '7vh',
        fontWeight: '300',
        margin: '0px',
      }}
      >
Our Team
      </h1>
    </TeamContainer>
    <TeamContainer>
      <h1 style={{
        padding: '6vh 0vh 3vh 0vh',
        color: 'rgb(0, 0, 0)',
        fontSize: '4vh',
        fontWeight: '300',
        margin: '0px',
      }}
      >
Founders
      </h1>
      <TeamInner>
        <Person
          path="./satvik.jpg"
          name="Satvik Sethi"
          role="Founder, Strategy & Branding"
          info="Binghamton University, Senior"
          lin="https://www.linkedin.com/in/satviksethi/"
          insta="https://www.instagram.com/satviksethi/"
          twit="https://twitter.com/satsethi14?lang=en"
          twitbool="1"
          size="0"
          desc="Satvik Sethi is a senior at Binghamton University, New York. He has worked with organizations like UNICEF, World Health Organization, and Mental Health America to conduct research, create policy, and to advocate for better mental health resources and accessibility for students around the world."
          par2="Satvik has been recognized as 1 of 8 inspiring mental health youth activists by Mashable, and 1 of 15 students changing collegiate mental health by Mental Health America for the impact he has carried through his advocacy. He has also had the opportunity to give talks at events like the Millennial Summit and has been interviewed in media outlets like Vice, Arianna Huffington’s Thrive Global, Times of India, and Subconscious, for his entrepreneurial and social endeavors that have inspired and motivated people of all ages around the world. Satvik has also garnered immense professional experience, having worked with organizations like Jones Lang LaSalle, Deloitte, EY, and Mastercard."
        />
      </TeamInner>
      <TeamInner>
        <Person path="./will.jpg" twitbool="0" name="Will Hunter " role="Co-Founder, Head of Product" info="Washington University, St. Louis, Sophomore" lin="https://www.linkedin.com/in/wlhunter/" insta="https://instagram.com/wlhunter00" twit="" size="0" desc="Will Hunter is a sophomore at Washington University, St. Louis and brings to the team an expansive knowledge and know-how in technological development. Will has expertise in full-stack development and delivering products that are intuitive for the user. He has development experience in multiple languages and frameworks and strength in scoping large projects to be worked on by a team. Will has a professional background in Data Science, working on multiple predictive analytics projects and is a part of Alvarez and Marsal’s forensic technology services team. He has a passion for data security and personal privacy and seeks to find technological solutions for large global problems." />
      </TeamInner>
      <h1 style={{
        padding: '6vh 0vh 3vh 0vh',
        color: 'rgb(0, 0, 0)',
        fontSize: '4vh',
        fontWeight: '300',
        margin: '0px',
        textAlign: 'center',
      }}
      >
Technology Development
      </h1>
      <TeamInner>
        <Person name="Michael Ginn" twitbool="0" role="Lead Software Engineer" info="Washington University, St. Louis" year="Sophomore" size="1" />
        <Person name="Giorgio Guttilla" twitbool="0" role="Lead Mobile App Developer" info="Washington University, St. Louis" year="Junior" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Simar Kapoor" twitbool="0" role="Data Scientist" info="Indiana University, Bloomington" year="Sophomore" size="1" />
        <Person name="Evan Molyneaux" twitbool="0" role="Mobile App Developer" info="Washington University, St. Louis" year="Junior" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Adam Jacobowitz" twitbool="0" role="Back End Developer" info="Ohio State University" year="Working Professional" size="1" />
        <Person name="Pryce Yebesi" twitbool="0" role="Mobile App Developer" info="Washington University, St. Louis" year="Sophomore" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Ajah Chukwuemeka" twitbool="0" role="Full Stack Developer" info="University of Nigeria" year="Working Professional" size="1" />
        <Person name="Kevin Huang" twitbool="0" role="Front End Developer" info="Harvard University" year="First Year" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Ayush Khandelwal" twitbool="0" role="Full Stack Developer" info="University of Massachusetts, Amherst" year="Junior" size="1" />
        <Person name="Katie Lund" twitbool="0" role="Mobile App Developer" info="Washington University, St. Louis" year="Sophomore" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="David Easton" twitbool="0" role="Mobile App Developer" info="Washington University, St. Louis" year="Junior" size="1" />
        <Person name="Zach Glabman" twitbool="0" role="Natural Language Engineering" info="Washington University, St. Louis" year="Sophomore" size="1" />
      </TeamInner>
      <h1 style={{
        padding: '6vh 0vh 3vh 0vh',
        color: 'rgb(0, 0, 0)',
        fontSize: '4vh',
        fontWeight: '300',
        margin: '0px',
        textAlign: 'center',
      }}
      >
Researchers
      </h1>
      <TeamInner>
        <Person name="Veeraj Shah" twitbool="0" role="Health Literacy" info="University of Maryland" year="Junior" size="1" />
        <Person name="Saydi Akgul" twitbool="0" role="Information Curator" info="Binghamton University, Master of Public Health" year="2nd Year Masters" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Zahraa Tounsi" twitbool="0" role="Information Curator" info="Binghamton University" year="Senior" size="1" />
        <Person name="Victor Lopez-Carmen" twitbool="0" role="Information Curator" info="Harvard Medical School" year="First Year" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Brendan Eappen" twitbool="0" role="Information Curator" info="Harvard Medical School" year="First Year" size="1" />
        <Person name="Ashwath Narayanan" twitbool="0" role="Resource Curator" info="George Washington University" year="" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Dan Sudit" twitbool="0" role="Resource Curator" info="New York University" year="" size="1" />
        <Person name="Parth Devalia" twitbool="0" role="Twitter Content Curator" info="University of Warwick" year="" size="1" />
      </TeamInner>
      <TeamInner>
        <Person name="Javier Suarez" twitbool="0" role="Graphic Designer" info="Binghamton University" year="Working Professional" size="1" />
        <Person name="Gus Velasquez" twitbool="0" role="Research" info="Harvard Medical School" year="" size="1" />
      </TeamInner>
    </TeamContainer>
    <div style={{ height: '5vh' }} />
  </div>
)

export default Team
