import React from 'react'
import { InfoContainer, InfoInner, Question } from './styles'
import './general.css'

const Info = () => (
  <div id="info">
    <InfoContainer>
      <InfoInner>
        <h1 style={{ textAlign: 'center', fontSize: '4vh' }}>Frequently Asked Questions (FAQs)</h1>
        <br />
        <Question>
          <h2>What is Project Covid?</h2>
          <p>
          The information that exists online about COVID-19 today is far too scattered and complicated for the average person to understand; there is also a growing concern over the spread of misinformation and “fake news” on the topic.
            <i> Project Covid is the solution to these problems. </i>
            {' '}
            22 passionate college students from around the country came together to identify, analyze, and curate verified information and resources from several online and offline sources in one place to help anyone seeking knowledge and information about COVID-19. Our diverse team consists of students with experience in healthcare, health literacy, policy, and technology.
            {' '}
          </p>
          <p>Our iOS and Android apps come loaded with live outbreak trackers, testing center information, breaking news stories from verified sources, live travel information, along with best practices, knowledge about the virus, educational toolkits, student resources, and crisis contact information.</p>
        </Question>
        <Question>
          <h2>What are your sources, what gives you credibility?</h2>
          <p>Our goal in creating this app was to identify and curate verified information and resources from trusted and reliable sources. All the information that you find on our platform has been curated only from these sources, and we have had a team of health literacy experts from Harvard Medical School and the University of Maryland thoroughly vet them prior to utilization.</p>
          <p>
          Below is the list of all the information sources that we’ve utilized:
            <ul>
              <li>The Lancet Commission</li>
              <li>World Health Organization (WHO)</li>
              <li>UNICEF</li>
              <li>Centers for Disease Control and Prevention (CDC)</li>
              <li>MMR Journal</li>
              <li>TIME Magazine</li>
              <li>John Hopkins University - Department of Medicine</li>
              <li>Harvard University - Department of Health</li>
              <li>University of Michigan - Department of Medicine</li>
              <li>Substance Abuse and Mental Health Services Administration (SAMHSA)</li>
              <li>National Health Commission of the People's Republic of China</li>
              <li>European Centre for Disease Prevention and Control</li>
            </ul>
            <p>We selected pre-existing, citable sources that we trust in our technological development, including:</p>
            <ul>
              <li>Embed Twitter Feed</li>
              <li>WHO</li>
              <li>Associated Press</li>
              <li>BBC</li>
              <li>New York Times</li>
              <li>Aljazeera</li>
              <li>Reuters</li>
              <li>NPR</li>
              <li>Bloomberg</li>
              <li>CDC</li>
              <li>Coronavirus.app</li>
            </ul>
          </p>
          <p>
The
            {' '}
            <a style={{ color: 'white' }} href="https://coronavirus.app/map"> coronavirus.app</a>
            {' '}
            is a web application that we integrated into mobile. This is the list of the
            {' '}
            {' '}
            <a style={{ color: 'white' }} href="https://www.notion.so/What-are-our-sources-4f3f962953f1418da96eae1b5fe06039"> sources</a>
            {' '}
            {' '}
            they use to keep the tracker up to date, in real-time. We received their permission to embed their web application in our mobile application.
          </p>
        </Question>
        <Question>
          <h2>What are you doing with my location?</h2>
          <p>We do not store any data related to an individual user’s location. Some of our features can be greatly enhanced by having access to location information, such as the testing center app, where we can tailor the information based on location by state. We plan on matching the latest headlines to local information, requiring the user’s location as well.</p>
          <p>A given location from the user is sent to our server in the form of coordinates. After sending necessary information about testing centers, outbreaks and current news to the user’s device, any and all location data extracted from the user is deleted.</p>
          <p>The local news feature has not yet been implemented.</p>
        </Question>
        <Question>
          <h2>Are you storing my symptom data?</h2>
          <p>
Much like the location information, we do not store any symptom data. The answers given by the user allows our algorithm to flag any notable symptoms, directly visible on the user’s device. Subsequently, the server deletes all symptom information.
          </p>
        </Question>
        <Question>
          <h2>What technology does the app utilize?</h2>
          <p>The app is coded in React Native. This allows us to deploy to both Android and iOS at the same time. We are using Expo, React Navigation, and React Elements inside the mobile app. We embed the twitter feed and coronavirus.app by using a WebView.</p>
          <p>The backend is coded in Node.JS and is an Express server. We use it to communicate with third-party APIs. This backend is hosted on Heroku. This is our backend server. All sensitive user information has tokens to ensure data security. All other data regarding the app is available to the public for GET requests, although the data received would be very similar to other public APIs. The APIs we use are:</p>
          <ul>
            <li>
              <a style={{ color: 'white' }} href="http://coronavirusapi.com/"> Coronavirusapi.com </a>
              {' '}
(Data on the spread of virus)
            </li>
            <li>News API (News Data)</li>
            <li>MapQuest (Reverse Geocoding)</li>
            <li>Spacy (NLP Processing)</li>
          </ul>
          <p>We store user permission tokens for push notifications and user services in a MongoDB database hosted by Atlas. We do not store any other data from our users.</p>
          <p>We have some backend functionality regarding word processing and machine learning in Python. This information is then routed back into Node.JS.</p>
        </Question>
        <Question>
          <h2>Who is part of the Founding Team?</h2>
          <p>Project Covid was founded by Satvik Sethi, a senior at Binghamton University, New York. Satvik has been deeply involved in the global mental health and healthcare ecosystem and has worked with organizations like UNICEF, World Health Organization, and Mental Health America to conduct research, create policy, and to advocate for better mental health resources and accessibility for students around the world. </p>
          <p>Satvik has been recognized as 1 of 8 inspiring mental health youth activists by Mashable, and 1 of 15 students changing collegiate mental health by Mental Health America for the impact he has carried through his advocacy. He has also had the opportunity to give talks at events like the Millennial Summit and has been interviewed in media outlets like Vice, Arianna Huffington’s Thrive Global, Times of India, and Subconscious, for his entrepreneurial and social endeavors that have inspired and motivated people of all ages around the world. Satvik has also garnered immense professional experience, having worked with organizations like Jones Lang LaSalle, Deloitte, EY, and Mastercard.</p>
          <p>Satvik connected with Will Hunter, a sophomore at Washington University, St. Louis and brought him on as a Co-Founder owing to Will’s knowledge and skills in technological development. Will has expertise in full-stack development and delivering intuitive products to users. His development experience spans multiple programming languages and frameworks, and he excels at scoping large team-based projects. Will has a professional background in Data Science, working on multiple predictive analytics projects and is part of Alvarez and Marsal’s forensic technology services team. He has a passion for data security and personal privacy and seeks to find technological solutions for large global problems.</p>
        </Question>
        <Question>
          <h2>Why is this app important?</h2>
          <p>There are two main reasons why we felt the need to create this app:</p>
          <ol>
            <li style={{ fontStyle: 'italic' }}>
            To help people find access to information and resources:
              <p style={{ fontStyle: 'normal' }}>Today, there are several online and offline sources available to everyone to find information relating to the COVID-19 pandemic. Unfortunately, through research and personal experience, we concluded that these sources are scattered and often difficult to comprehend for the average person. We wanted to create an extensive knowledge base of verified information and resources relating to the COVID-19 outbreak presented in an appealing and user-friendly manner.</p>
            </li>
            <li style={{ fontStyle: 'italic' }}>
            To prevent the spread of misinformation and “fake news”:
              <p style={{ fontStyle: 'normal' }}>While social media and instant communication have become a staple of interpersonal interaction, they have unfortunately fueled an increase in the dispersal of misinformation. Misinformation can be extremely harmful, especially with sensitive topics like COVID-19. By only utilizing trusted sources, we want to ensure that all aggregated knowledge and resources we share with our users are credible. </p>
              <p style={{ fontStyle: 'normal' }}>We provide complete transparency for all our sources, both for technological and non-technologically rooted information. You can find these sources on this page, as well as within each section on the mobile app. </p>
            </li>
          </ol>
        </Question>
        <Question>
          <h2>Are you making a profit from this?</h2>
          <p>No! We are a not-for-profit project made by college students with a desire to help. We identified a need for centralized information and wanted to provide it to our users at no cost, with no expectation of reciprocated gratification.</p>
        </Question>
        <Question>
          <h2>How can I volunteer?</h2>
          <p>We are proud of the fact that our team is 100% on a volunteer basis. We are actively looking to bring new volunteers with diverse experiences onto our team. If you are interested in joining team Project Covid:</p>
          <ul>
            <li>Email a copy of your resume</li>
            <li>Your preferred role (technological or non-technological)</li>
          </ul>
          <p>
With the subject line “Project Covid - Volunteer Application” to
            {' '}
            <a style={{ color: 'white' }} href="mailto:satvik@runawayapp.com"> satvik@runawayapp.com</a>
              .
            {' '}

          </p>
        </Question>
        <Question>
          <h2>What can we expect next?</h2>
          <p>Many new features are in development:</p>
          <ul>
            <li>A page featuring the latest information on global travel bans. Once we find a trustworthy and reliable data source, we will implement it right away.</li>
            <li>A smart news feed, pulling the most noteworthy headlines sources in the app itself. We want these to include local headlines to best tailor the app to the user. </li>
            <li>A sentiment-based news feature that will allow our users to filter news based on the type (positive or negative) of news content that you would like to see. </li>
            <li>When information about testing centers is more widely available, we would like a feature where you can find the nearest testing location to you on a map.</li>
            <li>We also have other broader plans to include more statistics and video content. If you have any other ideas on how to improve the app, please let us know!</li>
          </ul>
        </Question>
        <Question>
          <h2>How does Symptom Check Work?</h2>
          <p>
As of now, we are implementing a beta version of the symptom check. Right now, we only look at the answer to the first question, “Could you describe your overall wellness over the past few days? Do you have any unusual symptoms or feel unwell?”.
          </p>
          <p>The other questions are not yet used, but our algorithms are constantly changing. </p>
          <p>We look through the response and try to find the key symptoms outlined by the CDC. We then send back the symptoms that we found to your phone. The statistics regarding people with the symptoms were found in a CDC report. </p>
          <p>Legally, we cannot give a recommendation as to whether you should be tested. We always recommend that you contact any local health officials if you have any questions/doubts.</p>
        </Question>
        <Question>
          <h2>Why is this app associated with LFR International?</h2>
          <p>In March of 2020, LFR International adopted the Project Covid Initiative, partnering with founders Satvik Sethi and Will Hunter to expand international access to the application. LFR International now serves as the primary publisher and regulatory organization for Project Covid.</p>
          <p>We partnered with the original founders of Project Covid in order to increase access to trustworthy, reliable information regarding COVID-19 during this time of international crisis. The Project Covid mobile and web applications now are vital tools to fulfilling our mission of spreading equitable access to healthcare and empowering first responders around the globe. Thousands of LFR first responders around the globe use the Project Covid application to provide their communities with neccesary information regarding COVID-19, and LFR International is working with the development team to increase the international capabilities of Project Covid.</p>
        </Question>
      </InfoInner>
    </InfoContainer>
  </div>
)


export default Info
