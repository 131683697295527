import styled from 'styled-components'

// General
export const Font = styled.div`
     @import url('//fonts.googleapis.com/css?family=Montserrat:light');  
     font-family: 'Montserrat', sans-serif;
`

// Faqs
export const InfoContainer = styled.div`
    display: flex;
    justify-content: center;  
    width: 100%; 
    background-color: #008037; 
    padding-top: 100px;
    padding-bottom: 0px;
`
export const InfoInner = styled.div`
    max-width: 1000px;
    padding: 10vmin;
    color: white;
`

export const Question = styled.div`
    max-width: 1000px;
    padding-top: 10px;
`
