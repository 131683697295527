import React from 'react'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import NavBar from '../Faqs/navBar'
import {
  Font,
  InfoContainer, InfoInner, Question,
} from '../Faqs/styles'


const Demo = ({ setSect }) => (
  <Font style={{ maxHeight: '100vh' }}>
    <NavBar setSect={setSect} />
    <InfoContainer style={{ alignItems: 'center', height: '100vh', padding: 'none' }}>
      <InfoInner style={{ height: '100%', padding: 'none', maxWidth: '1200px' }}>
        <h1 style={{ textAlign: 'center', fontSize: '4vh', paddingBottom: '20px' }}>App Demo</h1>
        <Question>
          <iframe title="2" width="100%" height="315px" src="https://www.youtube.com/embed/vbWPZcs2v-g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </Question>
      </InfoInner>
    </InfoContainer>
  </Font>

)

export default Demo
