import styled from 'styled-components'
import { NavLink } from 'react-router-dom'


export const Main = styled.div`

`


// NavBar
export const NavbarContainer = styled.div`
    display: flex; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center;
    position: absolute; 
    top: 0; 
    height: 100px;
    background-color: white;
`

export const NavInner = styled.div`
    display: flex; 
    flex-wrap: nowrap; 
    width: 100%; 
    max-width: 1250px; 
    justify-content: space-between; 
    align-items: center; 
    padding-right: 20px;
`

export const NavbarLink = styled(NavLink)`
    padding: 0px 1vw; 
    font-size: 0.8rem; 
    color: black;
    text-decoration: none;
`


// Tagline

export const VideoImage = styled.div`
    display: flex; 
    align-items: center; 
    width: 50%; 
`

export const HeaderTagLine = styled.div`
    display: flex; 
    justify-content: space-between;
    width: 50%;
    max-width: 1140px;
    padding: 0px 100px 0px 100px;
    margin-top: 60px;
`

export const Heading = styled.div`
    width: 100%; 
`

export const MainContainer = styled.div`
    display: flex; 
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    justify-content: center;
`

// About
export const HomeContainer = styled.div`
    display: flex;
    justify-content: center;  
    width: 100%; 
    background-color: #008037; 
    border-radius: 0 0 50% 50% / 50px;
    padding-top: 20px;
    padding-bottom: 0px;
`
export const HomeInner = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;  
    align-items: center;
    max-width: 1000px;
    margin: 10vh;
    color: white;
`
// Email
export const EmailContainer = styled.div`
    display: flex;
    justify-content: center;  
    width: 100%; 
`
export const EmailInner = styled.div`
    display: flex; 
    justify-content: center; 
    max-width: 225px; 
    flex-direction: column;
    text-align: center;
    align-items: center;

`

// Test Features
export const TestContainer = styled.div`
    display: flex;
    justify-content: center;  
    width: 100%; 
`
export const TestInner = styled.div`
    display: flex; 
    justify-content: center; 
    max-width: 500px; 
    flex-direction: column;
    text-align: center;
    align-items: center;
`

// Team
export const TeamContainer = styled.div`
    display: flex;
    justify-content: center;  
    width: 100%; 
    background-color: white; 
    flex-direction: column;
    align-items: center;
    /* padding-top: 20px; */
`
export const TeamInner = styled.div`
    display: flex; 
    justify-content: center; 
    flex-direction: row;
    max-width: 1140px; 
`

// Person
export const TeamMember = styled.div`
    margin: 1vh 1vw;
    box-shadow: 0 3px 6px rgba(67, 160, 71, 0.2), 0 3px 6px rgba(67, 160, 71, 0.2);
    display: flex;
    justify-content: center; 
    flex-direction: row;
    align-items: center;
`

export const PersonImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1%;
    text-align: left;
    max-width: inherit;
`

// General
export const Font = styled.div`
     @import url('//fonts.googleapis.com/css?family=Montserrat:light');  
     font-family: 'Montserrat', sans-serif;
`

/* export const HomeDiv = styled.div`
    z-index: -1;
    opacity: ${({ fade }) => (fade ? '1' : '0')};
    transition: opacity 4s;
` */
