import React from 'react'
import { TeamMember, PersonImage } from './styles'

const Person = ({
  path, name, role, size, info, desc, year, mobile, lin, insta, twit, par2, twitbool,
}) => {
  const widths = ['80vw', '40vw']
  const imgWidths = ['25vh', '0']
  const paddingSizes = ['10px', '0']
  const aligns = ['left', 'center']
  const dis = ['', 'none']
  const distwit = ['none', '']
  return (
    <TeamMember style={{
      display: 'flex', flexDirection: 'column', maxWidth: widths[size], width: widths[size], borderRadius: '2px', padding: paddingSizes[size],
    }}
    >
      <img
        style={{
          height: imgWidths[size], width: 'auto', margin: '0px',
        }}
        src={path}
        alt=""
      />
      <PersonImage>
        <h2 style={{
          alignItems: aligns[size], textAlign: aligns[size], fontWeight: 'bold', fontSize: '2.5vh', color: 'black', padding: '10px 5px 0px 5px', margin: '0px', width: '95%',
        }}
        >
          {name}
          <a href={lin} rel="noopener noreferrer" target="_blank" style={{ display: dis[size] }}>
            <img style={{ height: '1rem', padding: '0px 1.5vw 0px 3vw' }} src="./linkedin.png" alt="" />
          </a>
          <a href={insta} rel="noopener noreferrer" target="_blank" style={{ display: dis[size] }}>
            <img style={{ height: '1rem', padding: '0px 1.5vw' }} src="./insta.png" alt="" />
          </a>
          <a href={twit} rel="noopener noreferrer" target="_blank" style={{ display: distwit[twitbool] }}>
            <img style={{ height: '1rem', padding: '0px 1.5vw' }} src="./twitter.png" alt="" />
          </a>
        </h2>
        <h3 style={{
          alignItems: aligns[size], textAlign: aligns[size], fontSize: '2.5vh', color: 'grey', padding: '10px 5px 5px 5px', margin: '0px',
        }}
        >
          {role}
        </h3>
        <p style={{
          alignItems: aligns[size], fontSize: '2.5vh', color: 'grey', textAlign: aligns[size], paddingLeft: '5px', paddingRight: '5px', margin: '0px', width: '95%',
        }}
        >
          {info}
        </p>
        <p style={{
          alignItems: aligns[size], fontSize: '2.5vh', color: 'grey', textAlign: aligns[size], margin: '0px', width: '98%', paddingLeft: '5px', paddingTop: paddingSizes[size],
        }}
        >
          {desc}
          <div style={{ paddingTop: paddingSizes[size] }}>
            {par2}
          </div>
        </p>
        <p style={{
          alignItems: aligns[size], fontSize: '2.5vh', color: 'grey', textAlign: aligns[size], margin: '0px', paddingBottom: '10px', width: '100%',
        }}
        >
          {year}

        </p>
      </PersonImage>
    </TeamMember>
  )
}

export default Person
