import React from 'react'
import Info from './info'
import NavBar from '../Faqs/navBar'
import {
  Font,
} from '../Faqs/styles'


const TC = ({ setSect }) => (
  <Font>
    <NavBar setSect={setSect} />
    <Info />
  </Font>
)

export default TC
