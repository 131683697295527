import React from 'react'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import { HomeContainer, HomeInner } from './styles'
import Email from './email'
import TestApp from './downloadApp'

const About = () => (
  <div id="about">
    <HomeContainer>
      <HomeInner>
        <BrowserView>
          <h1 style={{ fontSize: '2.5rem' }}>All the information and resources on the Coronavirus (COVID-19), easily accessible in one place</h1>
          <br />
          <p style={{ fontSize: '1.5rem' }}>Put together by a team of 22 students from around the United States, Project Covid provides a plethora of information and resources to help you prepare for and navigate the global pandemic. Our iOS and Android apps come loaded with live outbreak trackers, testing center information, breaking news stories from verified sources, live travel information, along with verified information on best practices, knowledge about the virus, educational toolkits, student resources, and crisis contact information.</p>
          <p style={{ fontSize: '1.5rem' }}> Co-Founders Satvik Sethi and Will Hunter started this project with the hope that we are able to help a lot of people around the world in finding the information and resources they need to protect themselves and their loved ones from the outbreak. </p>

        </BrowserView>
        <MobileView>
          <h1 style={{ fontSize: '3.5vmax' }}>All the information and resources on the Coronavirus (COVID-19), easily accessible in one place</h1>
          <br />
          <p style={{ fontSize: '2.5vmax' }}>Put together by a team of 22 students from around the United States, Project Covid provides a plethora of information and resources to help you prepare for and navigate the global pandemic. Our iOS and Android apps come loaded with live outbreak trackers, testing center information, breaking news stories from verified sources, live travel information, along with verified information on best practices, knowledge about the virus, educational toolkits, student resources, and crisis contact information.</p>
          <p style={{ fontSize: '2.5vmax' }}> Co-Founders Satvik Sethi and Will Hunter started this project with the hope that we are able to help a lot of people around the world in finding the information and resources they need to protect themselves and their loved ones from the outbreak. </p>

        </MobileView>
        <TestApp />
      </HomeInner>
    </HomeContainer>
  </div>
)


export default About
