import React, { useState, useEffect } from 'react'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import { Link, scroller } from 'react-scroll'
import About from './info'
import NavBar from './navBar'
import Team from './team'
import MobileTeam from './mobileTeam'
import {
  Font, HomeDiv,
} from './styles'


const Home = ({ sect }) => {
  // const [fade, setFade] = useState(false)
  useEffect(() => {
    scroller.scrollTo(sect, {
      offset: -50,
      duration: 1000,
      delay: 0,
      smooth: true,
      spy: true,
    })
    // setTimeout(() => setFade(true), 500)
  }, [])

  return (
    <Font>
      <NavBar />
      <BrowserView>
        <img
          style={{
            opacity: 'inherit', display: 'block', width: '100%', backgroundPosition: 'center', backgroundAttachment: 'scroll', paddingTop: '100px',
          }}
          src="./project-covid-banner2.jpg"
          alt=""
        />
      </BrowserView>
      <MobileView>
        <div style={{ display: 'flex', justifyContent: 'center', maxWidth: '1000px' }}>
          <img
            style={{
              overflow: 'hidden', maxHeight: '35vh', align: 'left', paddingTop: '100px',
            }}
            src="./project-covid-banner2.jpg"
            alt=""
          />
        </div>

      </MobileView>

      <About />
      <BrowserView>
        <Team />
      </BrowserView>
      <MobileView>
        <MobileTeam />
      </MobileView>
    </Font>
  )
}

export default Home
