import React, { useState } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import client from './client'
import Home from './containers/Home'
import Faqs from './containers/Faqs'
import TC from './containers/TC'
import PP from './containers/PP'
import Demo from './containers/Demo'
// https://github.community/t/my-react-project-doesnt-work-on-github-pages/10350/2
const App = () => {
  const [sect, setSect] = useState('')
  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/demo" component={() => <Demo setSect={setSect} />} />
        <Route exact path="/privacy_policy" component={() => <PP setSect={setSect} />} />
        <Route exact path="/terms&conditions" component={() => <TC setSect={setSect} />} />
        <Route exact path="/faqs" component={() => <Faqs setSect={setSect} />} />
        <Route exact path="/" component={() => <Home sect={sect} />} />
      </Switch>
    </Router>
  )
}

export default App
