import React, { useState } from 'react'
import { Link } from 'react-scroll'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
} from 'reactstrap'
import {
  NavbarLink, NavInner, NavbarContainer,
} from './styles'
import 'bootstrap/dist/css/bootstrap.min.css'

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <div>
      <BrowserView>
        <NavbarContainer>
          <NavInner>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ width: '100px' }} src="./project-covid-logo.jpg" alt="" />
              <img style={{ width: '90px', height: '80px' }} src="./LFR.png" alt="" />
            </div>
            <div className="menu" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/"><Link to="home">Home</Link></NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/"><Link to="about" spy smooth offset={-50} duration={1000}>About</Link></NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/"><Link to="download" spy smooth offset={-50} duration={1000}>Download</Link></NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/"><Link to="team" spy smooth offset={-50} duration={1000}>Our Team</Link></NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/faqs">FAQs</NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/privacy_policy">Privacy Policy</NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/terms&conditions">Terms & Conditions</NavbarLink>
              <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/demo">App Demo</NavbarLink>
              <a
                href="https://lfrinternational.org/"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  padding: '0px 1vw', fontSize: '0.8rem', color: 'black', textDecoration: 'none',
                }}
                to="/demo"
              >
LFR International
              </a>
              <a href="https://twitter.com/projectcovid" rel="noopener noreferrer" target="_blank">
                <img style={{ height: '1rem', padding: '0px 1vw' }} src="./twitter.png" alt="" />
              </a>
            </div>
          </NavInner>
        </NavbarContainer>
      </BrowserView>
      <MobileView>
        <NavbarContainer style={{ alignItems: 'flex-start', paddingTop: '25px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img style={{ display: 'flex', alignItems: 'center', width: '15vw' }} src="./project-covid-logo.jpg" alt="" />
            <img
              style={{
                display: 'flex', alignItems: 'center', width: '13vw', height: '12vw',
              }}
              src="./LFR.png"
              alt=""
            />
          </div>
          <div style={{ textAlign: 'center', width: '1000px' }}>
            <Navbar color="white" light expand="md">
              <NavbarBrand href="/" className="mr-auto" />
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                  <NavItem>
                    <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/">
                      <Link onClick={toggle} to="home">Home</Link>
                    </NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/">
                      <Link onClick={toggle} to="about" spy smooth offset={-50} duration={1000}>About</Link>
                    </NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/">
                      <Link onClick={toggle} to="download" spy smooth offset={-50} duration={1000}>Download</Link>
                    </NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink style={{ color: 'black', textDecoration: 'none' }} to="/">
                      <Link onClick={toggle} to="team" spy smooth offset={-50} duration={1000}>Our Team</Link>
                    </NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink onClick={toggle} style={{ color: 'black', textDecoration: 'none' }} to="/faqs">FAQs</NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink onClick={toggle} style={{ color: 'black', textDecoration: 'none' }} to="/privacy_policy">Privacy Policy</NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink onClick={toggle} style={{ color: 'black', textDecoration: 'none' }} to="/terms&conditions">Terms & Conditions</NavbarLink>
                  </NavItem>
                  <NavItem>
                    <NavbarLink onClick={toggle} style={{ color: 'black', textDecoration: 'none' }} to="/demo">App Demo</NavbarLink>
                  </NavItem>
                  <NavItem>
                    <a
                      href="https://lfrinternational.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        padding: '0px 1vw', fontSize: '0.8rem', color: 'black', textDecoration: 'none',
                      }}
                      to="/demo"
                    >
LFR International
                    </a>
                  </NavItem>
                  <NavItem onClick={toggle}>
                    <a href="https://twitter.com/projectcovid" rel="noopener noreferrer" target="_blank">
                      <img style={{ height: '1rem', padding: '0px 1vw' }} src="./twitter.png" alt="" />
                    </a>
                  </NavItem>

                </Nav>
              </Collapse>
            </Navbar>
          </div>

        </NavbarContainer>
      </MobileView>
    </div>
  )
}

export default NavBar
