import React from 'react'
import { Link } from 'react-scroll'
import Info from './info'
import NavBar from './navBar'
import {
  Font,
} from './styles'


const Faqs = ({ setSect }) => (
  <Font>
    <NavBar setSect={setSect} />
    <Info />
  </Font>
)

export default Faqs
