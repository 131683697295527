import React from 'react'
import {
  BrowserView,
  MobileView,
} from 'react-device-detect'
import { TestContainer } from './styles'

const Test = () => (
  <div id="download">
    <TestContainer>
      <BrowserView>
        <a href="https://tinyurl.com/covidinfoapp">
          <button
            type="button"
            style={{
              width: '40vw',
              border: 'none',
              outline: 'none',
              fontSize: '2.5rem',
              padding: '20px',
              marginTop: '50px',
              color: 'white',
              background: 'rgb(0, 50, 0)',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '15px',
              fontWeight: '300',
            }}
          >
         Download on App Store
          </button>

        </a>
      </BrowserView>
      <MobileView>
        <a href="https://tinyurl.com/covidinfoapp">
          <button
            type="button"
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              fontSize: '4vmax',
              padding: '20px',
              marginTop: '50px',
              color: 'white',
              background: 'rgb(0, 50, 0)',
              cursor: 'pointer',
              textAlign: 'center',
              borderRadius: '15px',
              fontWeight: '300',
            }}
          >
          Download on App Store
          </button>

        </a>
      </MobileView>
    </TestContainer>
  </div>

)

export default Test
